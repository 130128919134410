import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import firebase from "firebase/compat/app";

//pages
import Home from '../views/Home/Index.vue'
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '../views/Authentication/Dashboard/Index.vue')
// const Register = () => import(/* webpackChunkName: "Register" */ '../views/Authentication/Register/Index.vue')
const Login = () => import(/* webpackChunkName: "Login" */ '../views/Authentication/Login/Index.vue')
// const About = () => import(/* webpackChunkName: "About" */ '../views/About/Index.vue')
const Projects = () => import(/* webpackChunkName: "Projects" */ '../views/Projects/Index.vue')
const Contact = () => import(/* webpackChunkName: "Contact" */ '../views/Contact/Index.vue')
const CodeGenerator = () => import(/* webpackChunkName: "CodeGenerator" */ '../views/CodeGenerator/Index.vue')
const PageNotFound = () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFound/Index.vue')
const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy/Index.vue')
const TermsOfUse = () => import(/* webpackChunkName: "TermsOfUse" */ '../views/TermsOfUse/Index.vue')



const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiredAuthentication: false
    },
    component: Home
  },
/*  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/!* webpackChunkName: "About" *!/ '../views/About/Index.vue')
    meta: {
      requiredAuthentication: false
    },
    component: About
  },*/
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      requiredAuthentication: false
    },
    component: Contact
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    meta: {
      requiredAuthentication: false
    },
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-use',
    name: 'Terms of Use',
    meta: {
      requiredAuthentication: false
    },
    component: TermsOfUse
  },
  {
    path: '/code-generator',
    name: 'Code Generator',
    meta: {
      requiredAuthentication: true
    },
    component: CodeGenerator
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiredAuthentication: false
    },
    component: Login
  },
/*  {
    path: '/register',
    name: 'Register',
    meta: {
      requiredAuthentication: false
    },
    component: Register
  },*/
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiredAuthentication: true
    },
    component: Dashboard
  },
  {
    path: '/:pathMatch(.*)*', //will match everything and put it under `$route.params.pathMatch`
    name: 'Page not Found',
    meta: {
      requiredAuthentication: false
    },
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Check Authentication
  const requiresAuth = to.matched.some((record) => record.meta.requiredAuthentication)

  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // console.log('auth: yes - login: yes')
        next();
      } else {
        // console.log('auth: yes - login: no')
        next({ path: '/' });
      }
    });
  } else {
    // console.log('auth: no - login: no')
    next();
  }
})

export default router
